class AccountNavigation extends HTMLElement {
  constructor () {
    super ()
    this.linkEls = this.querySelectorAll('.js-nav-link')
    this.triggerId = this.dataset.triggerId

    if (this.linkEls && this.linkEls.length) {
      this.linkEls.forEach(linkEl => {
        if (this.triggerId) {
          const hash = linkEl.hash
          linkEl.addEventListener('click', (e) => {
            if (hash && hash === this.triggerId) {
              e.preventDefault()
              const elementTrigger = document.querySelector('#loyaltylion-beacon-frame')?.contentWindow.document.querySelector('div[data-testid="beacon"]')
              elementTrigger && elementTrigger.click()
            }
          })
        }

        if (linkEl.href && linkEl.href === window.location.href) {
          if (!linkEl.classList.contains('active')) {
            linkEl.classList.add('active')
          }
        }
      })
    }
  }
}

if (!customElements.get('account-navigation')) {
  window.customElements.define('account-navigation', AccountNavigation)
}